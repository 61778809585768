<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column md-6">
        <b-row class="align-items-center mb-2">
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class=""
              @click="$router.go(-1)"
            >
              <feather-icon icon="ArrowLeftIcon" class="mr-25" />
              <span>List</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6">
            <h4>Karma Catalog Details:</h4>
          </b-col>
          <b-col cols="12" md="6" />
        </b-row>
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Karma Name </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.karma_name }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6" v-if="userData.karma_category">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Karma Category
              </b-col>
              <b-col md="6" class="pb-50">
                <b-link
                  :to="{
                    name: 'view-karma-category',
                    params: { id: userData.karma_category.hashid },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ userData.karma_category.name }}
                </b-link>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6" v-if="userData.sub_karma_category">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Sub Karma Category
              </b-col>
              <b-col md="6" class="pb-50">
                <b-link
                  :to="{
                    name: 'view-karma-category',
                    params: { id: userData.sub_karma_category.hashid },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ userData.sub_karma_category.name }}
                </b-link>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Description </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.description }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Karma Point </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.karma_point }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Karma Status
              </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.status == "1" ? "Active" : "In Active" }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Karma Catalog Auditors
              </b-col>
              <b-col md="6" class="pb-50">
                <span v-for="role in userData.auditors" class="text-capitalize">
                  {{ role.replace(/_/g, " ") }},
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Karma Catalog Apply permission
              </b-col>
              <b-col md="6" class="pb-50">
                <span
                  v-for="role in userData.apply_permission"
                  class="text-capitalize"
                >
                  {{ role.replace(/_/g, " ") }},
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(data, index) in customFields" v-bind:key="index" md="6">
        <b-row>
          <b-col md="6" class="pb-50 font-weight-bold">
            {{ data.label }}
          </b-col>
          <b-col md="6" class="pb-50">
            {{ userData.get_customfields_data["field_" + data.id] }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userDataLocal: this.$cookies.get("userData"),
    };
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style>
</style>
